import React, { useState } from "react";
import { Button } from "react-bootstrap";

import {
  RealmAppProvider,
  useRealmApp,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";
import OnboardingRedirector from "../../../components/facilitator/onboarding_redirector";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import DashboardNav from "../../../components/navbar";
import { getRelationTitle } from "../../../utils";

import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FAC_NAVBAR_LINKS } from "../../../global";

const tableStyle = {
  border: "1px solid black",
  padding: "8px",
};
const tableStyleSmallFont = {
  ...tableStyle,
  fontSize: "13px",
};

function createSelectedMap(members) {
  var result = {};
  for (const member of Object.values(members)) {
    result[member["_id"]] = {
      mentor: member["mentor"],
      mentee: member["mentee"],
    };
  }
  return result;
}

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function autoMatchRecursive(mentors, selectedMap, membersMap, depth = 0) {
  if (mentors.length == 0) {
    return true;
  }

  const mentees = Object.keys(selectedMap).filter(
    (member) => selectedMap[member]["mentor"] == null
  );
  shuffle(mentees);

  const mentor = mentors.pop();
  // console.log(`${"-".repeat(depth)} processing ${membersMap[mentor]['username']}`)
  // console.log(`${"-".repeat(depth)} mentees available: ${mentees.map(m => membersMap[m]['username'])}`)
  // console.log(`${"-".repeat(depth)} selectedMap ${JSON.stringify(selectedMap)}`)
  const mentors_mentor = selectedMap[mentor]["mentor"];
  for (const mentee of mentees) {
    if (mentor == mentee || mentors_mentor == mentee) {
      continue;
    }

    const prevMentee = selectedMap[mentor]["mentee"];
    const prevMentor = selectedMap[mentee]["mentor"];
    selectedMap[mentor]["mentee"] = mentee;
    selectedMap[mentee]["mentor"] = mentor;
    // console.log(`${"-".repeat(depth)} assigning ${membersMap[mentee]['username']}`)

    if (autoMatchRecursive(mentors, selectedMap, membersMap, depth + 1)) {
      return true;
    } else {
      // console.log(`${"-".repeat(depth)} fail: setting mentee to prevMentee: ${prevMentee}`)
      selectedMap[mentor]["mentee"] = prevMentee;
      selectedMap[mentee]["mentor"] = prevMentor;
    }
  }

  mentors.push(mentor);
  return false;
}

const MentorMenteeSelector = ({
  members,
  currentMember,
  relation,
  selectedMap = {},
  onSelect = (member_id, relation, selected_member_id) => {},
}) => {
  const related_member_id = selectedMap[currentMember._id][relation];
  const related_member = members.filter((m) => m._id == related_member_id)[0];

  const related_member_username =
    related_member == null ? "Unassigned" : related_member["username"];

  const membersWithEmpty = [{ username: "Unassign", _id: null }].concat(
    members
  );

  const button_variant =
    related_member == null ? "outline-secondary" : "primary";

  var elm = (
    <DropdownButton
      id="dropdown-item-button"
      title={related_member_username}
      variant={button_variant}
      style={{ display: "inline-block", maxHeight: "200px" }}
      onSelect={(selected_member_id) => {
        onSelect(currentMember._id, relation, selected_member_id);
      }}
    >
      {membersWithEmpty.map((member, i) => {
        var name = member["username"];
        if (name != currentMember["username"]) {
          return (
            <Dropdown.Item key={i} eventKey={member["_id"]} as="button">
              {name}
            </Dropdown.Item>
          );
        } else {
          return null;
        }
      })}
    </DropdownButton>
  );
  return elm;
};

const SelectMembersPage = () => {
  function onSelectionChange(member_id, relation, selected_member_id) {
    console.log(
      member_id + " picked " + relation + " as " + selected_member_id
    );

    const changes = [];
    const other_relation = relation == "mentee" ? "mentor" : "mentee";
    var current_relation = selectedMap[member_id][relation];
    var current_other_relation = selectedMap[member_id][other_relation];

    current_relation =
      membersMap[current_relation] != null ? current_relation : null;
    current_other_relation =
      membersMap[current_other_relation] != null
        ? current_other_relation
        : null;

    if (current_relation == selected_member_id) {
      console.log("selection didn't change. Doing nothing.");
      return;
    } else if (
      selected_member_id == current_other_relation &&
      selected_member_id != null
    ) {
      alert(
        "You can't set " +
          membersMap[selected_member_id]["username"] +
          ` to be both ${getRelationTitle("mentor")} and ` +
          `${getRelationTitle("mentee")} of ` +
          membersMap[member_id]["username"]
      );
      return;
    } else {
      changes.push([member_id, relation, selected_member_id]);
      // member in current relation also needs to have their relation nulled
      if (current_relation != null) {
        console.log(
          member_id +
            "'s currently selected " +
            relation +
            " is " +
            current_relation +
            ". This change will set " +
            current_relation +
            "'s " +
            other_relation +
            " to no one. Are you sure?"
        );
        changes.push([current_relation, other_relation, null]);
      }
    }

    if (selected_member_id != null) {
      const current_relation_other_relation =
        selectedMap[selected_member_id][other_relation];
      if (current_relation_other_relation != null) {
        console.log(
          selected_member_id +
            "'s current selected " +
            other_relation +
            " is " +
            current_relation_other_relation +
            ". This change will set " +
            current_relation_other_relation +
            "'s " +
            relation +
            " to no one. Are you sure?"
        );
        changes.push([current_relation_other_relation, relation, null]);
      }
      changes.push([selected_member_id, other_relation, member_id]);
    }

    console.log("all changes:");
    for (const change of changes) {
      console.log(JSON.stringify(change));
    }

    // execute all changes
    const newSelectedMap = JSON.parse(JSON.stringify(selectedMap));
    for (const change of changes) {
      const [source_id, relation_to_set, target_id] = change;
      newSelectedMap[source_id][relation_to_set] = target_id;
    }
    changeMemberSelection(newSelectedMap);
  }

  function createSelectorMap(members_map, selected_map) {
    var result = {};
    var members_list = Object.values(members_map);

    for (const member of members_list) {
      var mentor_selector = (
        <MentorMenteeSelector
          members={members_list}
          currentMember={member}
          relation="mentor"
          selectedMap={selected_map}
          onSelect={onSelectionChange}
        />
      );
      var mentee_selector = (
        <MentorMenteeSelector
          members={members_list}
          currentMember={member}
          relation="mentee"
          selectedMap={selected_map}
          onSelect={onSelectionChange}
        />
      );
      result[member["_id"]] = {
        mentor: mentor_selector,
        mentee: mentee_selector,
      };
    }
    return result;
  }

  async function initializeSelectors() {
    var fetched_members = await realmApp.app.currentUser.functions.getCircleMembers();

    var members_map = {};
    for (const member of fetched_members) {
      members_map[member["_id"]] = member;
    }

    const selected_map = createSelectedMap(members_map);
    setMembersMap(members_map);
    setSelectedMap(selected_map);
  }

  async function setMentorMentee(member_id, relation, target_member_id) {
    const members = realmApp.db.collection("User");

    const member_name = membersMap[member_id]["username"];
    const target_member_name =
      target_member_id == null
        ? "No one"
        : membersMap[target_member_id]["username"];

    console.log(
      `setting ${member_name}'s ${relation} to ` + `${target_member_name}`
    );
    var set_data = {};
    set_data[relation] = target_member_id;

    const result = await members.updateOne(
      { _id: member_id },
      { $set: set_data }
    );
    console.log(result);
  }

  async function finalizeMatches(newSelectedMap) {
    // get the diff between current member mentors/mentees and selectedMap.
    setLoading("Saving. Please stay on this page...");

    const diff = [];
    for (const member of Object.values(membersMap)) {
      console.log(`checking ${member["username"]}`);
      const selected = newSelectedMap[member["_id"]];
      const current_mentor = member["mentor"];
      const current_mentee = member["mentee"];
      const new_mentor = selected["mentor"];
      const new_mentee = selected["mentee"];
      console.log(
        JSON.stringify([current_mentor, current_mentee, new_mentor, new_mentee])
      );
      console.log(
        JSON.stringify([
          membersMap[current_mentor] == null
            ? null
            : membersMap[current_mentor]["username"],
          membersMap[current_mentee] == null
            ? null
            : membersMap[current_mentee]["username"],
          membersMap[new_mentor] == null
            ? null
            : membersMap[new_mentor]["username"],
          membersMap[new_mentee] == null
            ? null
            : membersMap[new_mentee]["username"],
        ])
      );

      if (current_mentor != new_mentor) {
        diff.push([member["_id"], "mentor", new_mentor]);
        console.log("mentor changed");
      }
      if (current_mentee != new_mentee) {
        diff.push([member["_id"], "mentee", new_mentee]);
        console.log("mentee changed");
      }
    }

    // apply the diff.
    if (diff.length == 0) {
      console.log("no changes to finalize");
    } else {
      for (const d of diff) {
        await setMentorMentee(d[0], d[1], d[2]);
      }

      if (!Boolean(realmApp.app.currentUser.customData.matched_members)) {
        await realmApp.setMongoUserAttribute({ matched_members: true });
      }
      window.location.reload();
    }

    setLoading("");
  }

  async function removeUserFromCircle(member_id) {
    let member = membersMap[member_id];
    let prompt =
      `You will:\n` +
      `1. lose all your unsaved matches,\n` +
      `2. remove ${member["username"]} from your circle.\n\n` +
      `Are you sure?`;
    if (confirm(prompt)) {
      console.log(`removing ${member["username"]}`);
      try {
        // let result = await removeUserFromCircleBackend(member);
        let result = await realmApp.app.currentUser.functions.removeUserFromCircleBackend(
          member
        );
        if (result == true) {
          // renew user list, which will rebuild the selectors,
          // and clear any pending matches.
          initializeSelectors();
        } else if (typeof result === "string" || result instanceof String) {
          alert(result);
        } else {
          alert(`${JSON.stringify(result)}`);
        }
      } catch (e) {
        alert(`unknown error: ${JSON.stringify(e)}`);
      }
    } else {
      console.log(`removal cancelled`);
    }
  }

  function autoMatch(all = true) {
    console.log("automatching");
    var needMentor = Object.keys(selectedMap).filter((member) =>
      all ? true : selectedMap[member]["mentor"] == null
    );
    var needMentee = Object.keys(selectedMap).filter((member) =>
      all ? true : selectedMap[member]["mentee"] == null
    );
    shuffle(needMentor);
    shuffle(needMentee);

    if (needMentor.length != needMentee.length) {
      alert(
        `unexpected error: Number of members without a ${getRelationTitle(
          "mentee"
        )} is different ` +
          `than number of members without a ${getRelationTitle(
            "mentor"
          )}. This may be a database ` +
          "inconsistency. If it persists, please contact Mentoramp support."
      );
      return;
    }

    const emptySelectedMap = {};
    for (const member of Object.keys(selectedMap)) {
      emptySelectedMap[member] = { mentor: null, mentee: null };
    }
    const newSelectedMap = all
      ? emptySelectedMap
      : JSON.parse(JSON.stringify(selectedMap));

    console.log(
      `recursing with ${JSON.stringify(
        needMentee.map((m) => membersMap[m]["username"])
      )}`
    );

    if (autoMatchRecursive(needMentee, newSelectedMap, membersMap)) {
      changeMemberSelection(newSelectedMap);
    } else {
      alert("failed to find possible match.");
    }
  }

  const [loading, setLoading] = useState("");
  const realmApp = useRealmApp();
  const [membersMap, setMembersMap] = useState({});
  const [selectionUndos, setSelectionUndos] = useState([]);
  const [selectedMap, setSelectedMap] = useState({});
  const [selectorMap, setSelectorMap] = useState({});
  const [finalizeButton, setFinalizeButton] = useState(
    <button style={{ width: "20%" }} disabled>
      finalize
    </button>
  );
  const [undoButton, setUndoButton] = useState(
    <button style={{ width: "20%" }} disabled>
      undo
    </button>
  );

  function updateFinalizebutton(selectedMap) {
    const changes_vs_backend = Object.keys(selectedMap)
      .map((member) => {
        const [oldMentor, oldMentee, newMentor, newMentee] = [
          membersMap[member]["mentor"],
          membersMap[member]["mentee"],
          selectedMap[member]["mentor"],
          selectedMap[member]["mentee"],
        ];

        const memberChanges = [];
        if (oldMentor != newMentor) {
          memberChanges.push([member, "mentor", oldMentor]);
        }
        if (oldMentee != newMentee) {
          memberChanges.push([member, "mentee", oldMentee]);
        }
        return memberChanges;
      })
      .flat(1);

    if (changes_vs_backend.length > 0) {
      setFinalizeButton(
        <button
          style={{ width: "20%" }}
          onClick={() => {
            finalizeMatches(selectedMap);
          }}
        >
          finalize
        </button>
      );
    } else {
      setFinalizeButton(
        <button style={{ width: "20%" }} disabled>
          finalize
        </button>
      );
    }
  }
  async function changeMemberSelection(newSelectedMap) {
    const changes_vs_ui_selection = Object.keys(newSelectedMap)
      .map((member) => {
        const [oldMentor, oldMentee, newMentor, newMentee] = [
          selectedMap[member]["mentor"],
          selectedMap[member]["mentee"],
          newSelectedMap[member]["mentor"],
          newSelectedMap[member]["mentee"],
        ];

        const memberChanges = [];
        if (oldMentor != newMentor) {
          memberChanges.push([member, "mentor", oldMentor]);
        }
        if (oldMentee != newMentee) {
          memberChanges.push([member, "mentee", oldMentee]);
        }
        return memberChanges;
      })
      .flat(1);

    if (changes_vs_ui_selection.length > 0) {
      selectionUndos.push(changes_vs_ui_selection);
      setSelectionUndos([...selectionUndos]);
      setSelectedMap(newSelectedMap);
    }
  }

  React.useEffect(() => {
    if (realmApp.app.currentUser) {
      initializeSelectors();
    }
  }, []);

  React.useEffect(() => {
    setSelectionUndos([]);
    setSelectedMap(createSelectedMap(membersMap));
  }, [membersMap]);

  React.useEffect(() => {
    if (
      Object.keys(selectedMap).length > 0 &&
      Object.keys(membersMap).length > 0
    ) {
      const selector_map = createSelectorMap(membersMap, selectedMap);
      setSelectorMap(selector_map);
    }

    updateFinalizebutton(selectedMap);
  }, [selectedMap]);

  async function undoLastSelection() {
    const changes = selectionUndos.pop();
    for (const [member, relation, other_member] of changes) {
      selectedMap[member][relation] = other_member;
    }
    setSelectedMap({ ...selectedMap });
    setSelectionUndos([...selectionUndos]);
  }

  React.useEffect(() => {
    console.log("checking selectionUndos");
    if (selectionUndos.length > 0) {
      setUndoButton(
        <button style={{ width: "20%" }} onClick={undoLastSelection}>
          undo
        </button>
      );
    } else {
      setUndoButton(
        <button style={{ width: "20%" }} disabled>
          undo
        </button>
      );
    }
  }, [selectionUndos]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <DashboardNav
        link="/facilitator/dashboard"
        menuItems={FAC_NAVBAR_LINKS}
        settingsLink="/facilitator/dashboard/settings"
      />
      <div style={{ width: "1000px", maxWidth: "1000px" }}>
        <span style={{ fontSize: "40px", width: "60%" }}>Match Members</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button style={{ width: "32%" }} onClick={() => autoMatch(true)}>
            auto-match all
          </button>
          <button style={{ width: "32%" }} onClick={() => autoMatch(false)}>
            auto-match unmatched
          </button>
          {undoButton}
          {finalizeButton}
          <br />
        </div>
        <div style={{ textAlign: "center" }}>{loading}</div>
        <div style={{ backgroundColor: "#dddddd", padding: "10px" }}>
          <table style={{ width: "100%" }}>
            <tr>
              <th>Remove</th>
              <th>Member</th>
              <th>Self Traits</th>
              <th>Wanted {getRelationTitle("mentor", true)} Traits</th>
              <th>{getRelationTitle("mentee", true)}</th>
              <th>{getRelationTitle("mentor", true)}</th>
            </tr>
            {Object.keys(selectorMap)
              .filter((member_id) => membersMap[member_id] != null)
              .map((member_id) => {
                return (
                  <tr>
                    <td style={tableStyle}>
                      <Button
                        style={{ width: "fit-content", minWidth: "4rem" }}
                        className="dark-gray-button btn-sm"
                        onClick={() => {
                          removeUserFromCircle(member_id);
                        }}
                      >
                        X
                      </Button>
                    </td>
                    <td style={tableStyle}>
                      {membersMap[member_id]["username"]}
                    </td>
                    <td style={tableStyleSmallFont}>
                      {membersMap[member_id]["mentorship_self_descr"]}
                    </td>
                    <td style={tableStyleSmallFont}>
                      {membersMap[member_id]["mentorship_other_descr"]}
                    </td>
                    <td style={tableStyle}>
                      {selectorMap[member_id]["mentee"]}
                    </td>
                    <td style={tableStyle}>
                      {selectorMap[member_id]["mentor"]}
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      {loading}
    </div>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <SelectMembersPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
